import React from "react";
import Video from '../video';

const AppFeatures = ({ title, text, image, video, reversed }) => {
  return (
    <div className={`flex items-center-ns flex-column flex-row-${reversed ? 'reverse-' : ''}ns mb3`}>
      <div className="w-100 w-50-ns pa3">
        {image!=null ? <img src={image} className="w-100" style={{maxWidth: "400px"}}/> : ''}
        {video!=null ? <Video video={video} width={"100%"} height={"auto"}/> : ''}
      </div>
      <div className="w-100 w-50-ns pa3">
        <h3 className="f4 f3-ns mb3 lh-title">{title}</h3>
        <p className="f5 f4-ns lh-title">{text}</p>
      </div>
    </div>
  );
};

export default AppFeatures;
