import React from "react";
import { Link } from "gatsby";
import Icon from "../icon";

const ProductsNavItem = ({ text, link, icon, active, width }) => {
  return (
    <Link to={link} className={`products-nav-item dark-gray ${active ? 'active' : ''}`}>
      <Icon icon={icon} />
      <span className="f6 b ph2">{text}</span>
    </Link>
  );
};

export default ProductsNavItem;
