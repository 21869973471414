import React from 'react';
import './products-nav.scss'

export const ProductsNav = ({ children }) => (
  <div className="products-nav">
    {children}
  </div>
);

export default ProductsNav;
