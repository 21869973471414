import React from "react";

export const Video = ({ width, height, poster, video, webm, borderColor, className }) => (
  <video
    className={`mw-100 br-100 bw4 b--solid ${className}`}
    preload="yes"
    autoPlay
    loop
    muted
    width="100%"
    height="auto"
    poster={poster}>
    <source src={video} type="video/mp4"/>
    <source src={webm} type="video/webm"/>
  </video>
);

export default Video;
