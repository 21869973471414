import React, { Fragment } from "react";
import Layout from "../../components/layout";
import ProductsNav from "../../components/navigation/ProductsNav";
import ProductsNavItem from "../../components/navigation/ProductsNavItem";
import VideoFirstApp from "../../components/icon/icons/vf-icon-rgb.icon";
import VideoFirstCapture from "../../components/icon/icons/capture.icon";
import PrimaryButton from "../../components/button";
import GitHubIcon from "../../components/icon/icons/github.icon";
import AppFeatures from "../../components/appfeatures";
import Container from "../../components/container";
import ImagePlaceholder from "../../components/landing/vft-placeholder-video.png";

const CapturePage = props => (
  <Layout pageTitle="Capture - Products ">
    <main>
      <ProductsNav>
        <ProductsNavItem text={"Video First App"} icon={VideoFirstApp} link={"/products/app/"}
                         active={false}/>
        {/*<ProductsNavItem text={"Video First Capture"} icon={VideoFirstCapture}*/}
                         {/*link={"/products/capture/"}*/}
                         {/*active={true}/>*/}
      </ProductsNav>
      <Container extraClasses={"pv3 pv4-ns tc"} verticalPadding={false} maxWidth={1200}>
          <img src={ImagePlaceholder} className={"w-60 mh-auto mb4 mb5-ns"}/>
        <h3 className="f4 f3-ns tc normal">On the go screen test footage with <span className="sb">Video First Capture</span>
        </h3>
        <span className="f4 f3-ns tc">First we created the perfect companion to Video First App, our easy-to-use video capture tool. Then we made it Open Source to help support our Dev community.</span>
        <p><PrimaryButton text={"Get it on GitHub"}
                          link={"https://github.com/videofirst/vft-capture"} icon={GitHubIcon}
                          extraClasses={"inline-flex justify-center items-center"}
                          targetBlank={true}/></p>
      </Container>
      <Container extraClasses={"bg-pale-grey"} maxWidth={1200}>
        <AppFeatures title={"Flexible screen capture tool"}
                     text={<Fragment>Problem: Tired of grappling with capture apps that don't hit
                       the mark every time?<br/><br/>Set a specific area, or record full
                       screen.</Fragment>}
                     reversed
        />
      </Container>
      <Container maxWidth={1200}>
        <AppFeatures title={"Upload to your Video First account"}
                     text={"Save to disc or upload your project."}
        />
      </Container>
      <Container extraClasses={"bg-pale-grey"} maxWidth={1200}>
        <AppFeatures title={"Stop/start record for multiple clips"}
                     text={"Multiple clips, one movie."}
                     reversed
        />
      </Container>
      <Container extraClasses={"bg-accent-blue tc"} maxWidth={1200}>
        <h3 className={"f3"}>Try the future of testing today</h3>
        <p>Save time and increase the effectiveness of your product when you test using Video
          First</p>
      </Container>
    </main>
  </Layout>
);

export default CapturePage;
